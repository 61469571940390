.pdi-layout-wrap,
.pdi-layout-wrap > .layout > div {
  flex: 1;
}

.pdi-layout-content {
  padding-left: 25px;
  padding-right: 25px;
}

.RaLayout-content-4 .show-page > div > .MuiPaper-rounded {
  box-shadow: none;
}

.pdi-tabs-transparent > .MuiTabs-root {
  position: relative;
}
.pdi-tabs-transparent > .MuiTabs-root:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #cacaca;
}
.pdi-tabs-transparent .MuiTabs-indicator {
  z-index: 1;
}
.pdi-tabs-transparent > div:last-child {
  padding: 20px 0;
}
